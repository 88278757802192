document.addEventListener("DOMContentLoaded", () => {
  const initFancybox = () => {
    $('[data-fancybox="gallery"]').fancybox({
      hash: false, // Disable hash in the URL
    });

    $(document).on("afterClose.fb", function () {
      if (location.hash) {
        history.replaceState(null, document.title, window.location.pathname);
      }
    });
  };

  const slider = () => {
    // Custom Slider Initialization
    const initCustomSlider = () => {
      let slides,
        selectors,
        currentIndex = 0,
        nextIndex = 1,
        interval;

      const cycle = (index) => {
        nextIndex = index !== undefined ? index : nextIndex;

        const currentSlide = slides[currentIndex];
        const nextSlide = slides[nextIndex];
        const currentSelector = selectors[currentIndex];
        const nextSelector = selectors[nextIndex];

        // Add smooth transitions
        [currentSlide, nextSlide].forEach((slide) => {
          slide.style.transition = "opacity 1s ease-in-out, z-index 1s ease";
        });

        currentSlide.classList.remove("active");
        currentSlide.style.zIndex = "0";

        nextSlide.classList.add("active");
        nextSlide.style.zIndex = "1";

        currentSelector.classList.remove("current");
        nextSelector.classList.add("current");

        currentIndex =
          index !== undefined
            ? nextIndex
            : currentIndex < slides.length - 1
            ? currentIndex + 1
            : 0;

        nextIndex = currentIndex + 1 < slides.length ? currentIndex + 1 : 0;
      };

      slides = document.querySelectorAll(".slide");
      selectors = document.querySelectorAll(".selector");

      if (slides.length > 0) {
        slides[0].classList.add("active");
        selectors[0].classList.add("current");

        interval = setInterval(cycle, 4000);

        selectors.forEach((selector, index) => {
          selector.addEventListener("click", () => {
            if (index !== currentIndex) {
              clearInterval(interval);
              cycle(index);
              interval = setInterval(cycle, 4000);
            }
          });
        });
      }
    };

    // Swiper Initialization
    const initSwiper = () => {
      const interleaveOffset = 0.05; // Reduced for a more subtle effect

      const swiperOptions = {
        loop: true,
        speed: 2500, // Increased for smoother transitions
        parallax: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          progress(swiper) {
            swiper.slides.forEach((slide) => {
              const slideProgress = slide.progress;
              const innerOffset = swiper.width * interleaveOffset;
              const innerTranslate = slideProgress * innerOffset;
              slide.querySelector(
                ".slide-inner"
              ).style.transform = `translate3d(${innerTranslate}px, 0, 0)`;
            });
          },
          touchStart(swiper) {
            swiper.slides.forEach((slide) => {
              slide.style.transition = "";
            });
          },
          setTransition(swiper, speed) {
            swiper.slides.forEach((slide) => {
              slide.style.transition = `${speed}ms`;
              slide.querySelector(
                ".slide-inner"
              ).style.transition = `${speed}ms`;
            });
          },
        },
      };

      new Swiper(".swiper-container", swiperOptions);

      // Set background images for slides
      document.querySelectorAll(".slide-bg-image").forEach((bgImage) => {
        const backgroundUrl = bgImage.dataset.background;
        if (backgroundUrl) {
          bgImage.style.backgroundImage = `url(${backgroundUrl})`;
        }
      });
    };

    const lazyLoadSlides = () => {
      const slides = document.querySelectorAll(".slide-inner");

      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const slide = entry.target;
              const bgImage = slide.getAttribute("data-bg");

              if (bgImage) {
                slide.style.backgroundImage = `url(${bgImage})`;
                slide.classList.add("loaded");
                observer.unobserve(slide); // Deja de observar después de cargar
              }
            }
          });
        },
        { threshold: 0.1 } // Carga cuando el 10% del slide es visible
      );

      slides.forEach((slide) => observer.observe(slide));
    };

    // Initialize Custom Slider and Swiper
    initCustomSlider();
    initSwiper();
    lazyLoadSlides();
  };

  const entrance = () => {
    const mediaQuery = window.matchMedia("(min-width: 600px)");

    if (mediaQuery.matches) {
      const fadersLeft = document.querySelectorAll(".fade-left");
      const fadersRight = document.querySelectorAll(".fade-right");

      const appearOptions = {
        threshold: 0,
        rootMargin: "0px 0px -50px 0px",
      };

      const appearOnScroll = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          } else {
            entry.target.classList.remove("show");
          }
        });
      }, appearOptions);

      fadersLeft.forEach((fader) => appearOnScroll.observe(fader));
      fadersRight.forEach((fader) => appearOnScroll.observe(fader));
    }
  };

  $(document).ready(() => {
    initFancybox();
    slider();
    entrance();
  });
});
